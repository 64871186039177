<template>
    <election-module-card title="Antal valgte medlemmer" card-icon-class="fad fa-ballot fa-3x"
                          description="Her angives hvor mange medlemmer der skal vælges til menighedsrådet.​"
                          button-icon-class="fal fa-ballot"
                          button-text="Gå til antal valgte medlemmer"
                          card-active="true"
                          :route="route">
        <div slot="footer" v-if="electionYearInfo != null">
            <small v-if="!lodash.some(electionEvents, (event) => { return event.eventCode === eventTypes.InputMembersSaved; })">
                Deadline for angivelse: {{moment(electionYearInfo.deadlineInputMembers).format('D. MMMM YYYY') }}
            </small>
            <small class="text-italic" v-if="lodash.some(electionEvents, (event) => { return event.eventCode === eventTypes.InputMembersSaved; })">
                Seneste opdatering indsendt {{moment(lastUpdatedEvent.timestamp).format('D. MMMM YYYY') }} 
            </small>
        </div>
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    import EventCodeType from "@/services/EventCodeType";
    import lodash from 'lodash';
    
    export default {
        name: "ElectionBoardEditCard",
        extends : ElectionModuleCard,
        data(){
            return{
                eventTypes : EventCodeType      
            }
        },
        components:{
            ElectionModuleCard
        },
        computed:{
            lastUpdatedEvent(){
                if(!lodash.some(this.electionEvents, (event) => event.eventCode === EventCodeType.InputMembersSaved))
                    return [];
                
                var sortedArray = lodash.sortBy(this.electionEvents.filter((event) => event.eventCode === EventCodeType.InputMembersSaved), ['timeStamp']);
                
                return lodash.last(sortedArray);
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>