enum AccessRoleTypes {
    Member = 1,
    Accounting = 2,
    Restrictedgeed = 4,
    Confirmand = 8,
    ConfirmandAdministrator = 16,
    ElectionBoard = 32,
    ElectionAdminGlobal = 64,
    Constitution = 128,
    MenigtMedlem = 256,
    Salary = 512,
    Persontjek = 1024,
    PersontjekProvst = 2048,
    FinancialDescendantsList = 4096,
    FinancialDescendantsAdmin = 8192

}
export default AccessRoleTypes;

