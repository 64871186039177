<template>
    <election-module-card title="Udfyldningsvalg" card-icon-class="fad fa-poll-people fa-3x"
                          description="Her kan du arbejde med et evt. udfyldningsvalg."
                          button-icon-class="fal fa-poll-people"
                          button-text="Gå til udfyldningsvalg"
                          :card-active="cardActive"
                          :route="route">
        <small slot="footer">
            <em class="text-muted">Benyttes hvis der skal være udfyldningsvalg</em>
        </small>
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";

    export default {
        name: "ElectionBoardFillingCard",
        extends : ElectionModuleCard,
        components:{
            ElectionModuleCard
        }
    }
</script>

<style scoped>

</style>