import Vue from 'vue';
import Vuex from 'vuex';
import APIService from './services/apiservice';
import Q from 'Q';
import lodash from 'lodash';
import {ElectionAppState} from "@/services/Model/Election/ElectionAppState";
import AccessRoleTypes from "@/services/AccessRoleTypes";
import {ElectionDocumentInfo} from "@/services/Model/Election/ElectionDocumentInfo";
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";
Vue.use(Vuex);

// @ts-ignore
export default new Vuex.Store({
    state: {
        userInfo: {},
        userRoles: sessionStorage.getItem("userRoles") ? JSON.parse(<string>sessionStorage.getItem("userRoles")) : {userRoles: []},
        activeUserRole: {groupValue: "", groupName: "", accessRoles: 0},
        activeYear: 2020,
        years: [],
        module: "Start",
        token: sessionStorage.getItem('token') ? JSON.parse(<string>sessionStorage.getItem('token')) : null,
        area: "Dataarkiv",
        electionYear: 2024,
        electionEvents: [],
        electionDocument: null,
        electionYearInfo: {},
        electionBoardMembers: [],
        failedUserRequest: 0,
        failedRolesRequest: 0,
        dataLoading: false,
        membersDataLoading: false,
        electionType: 10,
        sbl_priest: {},
        sblDioceseSettings: null,
        financialYear : {},
        financialStatementYears: [],
        financialBudgetYears: [],
        financialQuarterReportYears : [],
        aggregatedCongregations : []
    } as ElectionAppState,
    mutations: {
        setFinancialYear(state, value){
            state.financialYear = value;
        },
        setSblPriest(state, value) {
            state.sbl_priest = value;
        },
        setElectionYear(state, value) {
            state.electionYear = value;
        },
        setElectionEvents(state, value) {
            state.electionEvents = value;
        },
        setElectionYearInfo(state, value) {
            state.electionYearInfo = value;
        },
        setElectionDocument(state, value) {
            state.electionDocument = value;
            if (value)
                state.electionType = value.electionType;
        },
        setActiveArea(state, value) {
            state.area = value;
        },
        setUserInfo(state, value) {
            state.userInfo = value;
        },
        setUserRoles(state, value) {

            sessionStorage.setItem("userRoles", JSON.stringify(value));
            state.userRoles = value;
        },
        setActiveYear(state, value) {
            state.activeYear = value;
        },
        SET_YEARS(state, years) {
            state.years = years;
        },
        setActiveModule(state, value) {
            state.module = value;
        },
        setActiveUserRole(state, value) {
            value.groupValue = value.groupValue.toString();

            state.activeUserRole = value;
        },
        setSblDioceseSettings(state, value) {
            state.sblDioceseSettings = value;
        },
        setFinancialBudgetYears(state,value){
            state.financialBudgetYears = value;
        },
        setFinancialStatementYears(state,value){
            state.financialStatementYears = value;
        },
        setFinancialQuarterReportYears(state,value){
            state.financialQuarterReportYears = value;
        },
        setAggregatedCongregations(state,value){
            state.aggregatedCongregations = value;
        },
        setToken(state, value) {
            sessionStorage.setItem("token",JSON.stringify(value))
            state.token = value;
        }
    },
    actions: {
        loadFinancialYear({state,commit},{year}) {
            return APIService.GET(`/financialyears/${year}`)
                .then(r => {
                    console.log("committing", r);
                    commit('setFinancialYear', r.data);
                });
        },
        loadAggregatedCongregations({state,commit},{mid,year}){
            return APIService.GET(`/aggregations/mr/${mid}/${year}`)
                .then(r => {
                    commit('setAggregatedCongregations',r.data);
                });
        },
        loadFinancialStatementYears({state,commit}) {
            return APIService.GET(`/FinancialYears/statement`)
                .then(r => {
                    commit('setFinancialStatementYears',r.data);
                });
        },
        loadFinancialBudgetYears({state,commit}) {
            return APIService.GET(`/FinancialYears/budget`)
                .then(r => {
                    commit('setFinancialBudgetYears',r.data);
                });
        },
        loadFinancialQuarterReportYears({state,commit}) {
            return APIService.GET(`/FinancialYears/quarterreport`)
                .then(r => {
                    commit('setFinancialQuarterReportYears',r.data);
                });
        },
        clearElectionDocument({state, commit}) {
            commit('setElectionDocument', null);
        },
        loadActiveUserRole({getters, dispatch, commit, state}, route) {
            
            let level = route.meta.level ? route.meta.level : [0]

            if (state.activeUserRole && route === state.activeUserRole)
                return;
            
            
            let role = state.userRoles.userRoles.filter(r => level.indexOf(r.level) > -1 && r.groupValue === route.params.mid);
            
            
            if (role.length > 0) {
                commit("setActiveUserRole", role[0]);
            } else {

                if (state.electionDocument) {
                    let r = [];
                    state.electionDocument.aliases.forEach(alias => {
                        if (r.length == 0) {
                            r = state.userRoles.userRoles.filter(r => r.groupValue === alias.toString());
                            if (r.length > 0) {
                                commit("setActiveUserRole", r[0]);
                                return;
                            }
                        }
                    })


                    if (r.length === 0 && getters.hasElectionAdminGlobalRole) {

                        commit("setActiveUserRole", {
                                accessRoles: 33,
                                groupName: state.electionDocument.midName,
                                groupValue: route.params.mid.toString(),
                                level: 3
                            }
                        );
                    }

                }
            }
        },
        loadElectionDocument({commit, state, dispatch}, {year, mid, route}) {
            
            if (state.electionDocument && (state.electionDocument.mid === mid || state.electionDocument.aliases.indexOf(mid) > -1)) {

                return;
            }

            if (mid >= 7000) {
                state.dataLoading = true; // Indicate loading

                APIService.GET(`/Election/Board/${year}/${mid}/Document`)
                    .then((response: any) => {

                        if (response.status === 200) {
                            commit('setElectionDocument', response.data);
                        }


                    })
                    .catch((error) => {

                    })
                    .then(() => {
                        state.dataLoading = false;
                        dispatch("loadActiveUserRole", route);
                    })
                ;
            } else {
                state.electionDocument = null;
                dispatch("loadActiveUserRole", route);
            }
        },
        loadElectionBoardMembers({commit, state}, electionDocument: ElectionDocumentInfo) {
            state.membersDataLoading = true;

            var midCollection = lodash.concat(electionDocument.aliases, electionDocument.mid);

            var midBoardQueries = lodash.map(midCollection, (mid) => {
                return APIService.GET(`/Election/Board/${mid}/Members`);
            });
            Promise.all(midBoardQueries)
                .then((responseCollection: any) => {
                    // Select arrays of members for-each MR, then Flatten (array[array[]...], uniqueBy(personKey), orderBy(personName)) : pust pust - motion :)
                    var electionBoardMembers = lodash.orderBy(lodash.uniqBy(lodash.flatten(lodash.map(responseCollection, (response: any) => {
                        return response.data;
                    })), "personKey"), "personName");
                    state.electionBoardMembers = electionBoardMembers;
                })
                .then(() => {
                    state.membersDataLoading = false;
                })
            ;
        },
        loadElectionEvents({commit, state}, {year, mid}) {

            APIService.GET(`/Election/Board/${year}/${mid}/Events`)
                .then((response: any) => {

                    commit('setElectionEvents', response.data);
                })
                .catch((error) => {

                })
            ;
        },
        loadNewestElectionYear({commit, state, dispatch}, mid) {
            if(mid < 7000)
                return;
            APIService.GET(`/election/electionyear/${mid}`)
                .then((response: any) => {
                    commit("setElectionYear", response.data);
                    dispatch("loadElectionYearInfo",response.data);
                })
                .catch((error) => {

                })
            ;
        },
        loadElectionYearInfo({commit, state}, year) {

            APIService.GET(`/Election/${year}`)
                .then((response: any) => {
                    commit("setElectionYearInfo", response.data);
                })
                .catch((error) => {

                })
            ;
        },
        loadYears(context) {
            var years = [];

            var d = new Date();
            var startYear = d.getFullYear() - 4;
            var endYear = d.getFullYear() + 2;

            for (var y = startYear; y <= endYear; y++) {
                years.push(y);
            }

            context.commit("SET_YEARS", years);
        },
        loadUserInfo(context) {

            if (!context.state.userInfo.email) {
                APIService.GET("user/current").then(d => {
                    context.state.failedUserRequest = 0;
                    if (d)
                        context.commit('setUserInfo', d.data);

                }).catch(err => {


                    if (err.response.status === 401 && context.state.failedUserRequest < 2) {

                        context.state.failedUserRequest++;
                        context.dispatch('loadUserInfo');
                    }

                })
            }
        },
        loadUserRoles(context) {
            if (context.state.userRoles.userRoles.length === 0)
                return APIService.GET("user/roles").then(d => {
                    context.state.failedRolesRequest = 0;
                    if (d)
                        context.commit("setUserRoles", d.data);
                }).catch(err => {

                    if (err.response.status === 401 && context.state.failedUserRequest < 2) {

                        context.state.failedRolesRequest++;
                        // context.dispatch('loadUserRoles');
                    }

                });
            else
                return Q.fcall(() => {
                });
        },
        loadSblDioceseSettings({commit}, {dioceseId}) {
            
            APIService.GET(`SBL/Settings/Diocese/${dioceseId}`)
                .then((response: any) => {
                    commit('setSblDioceseSettings', response.data);
                })
                .catch((error) => {

                });
        },
        logout(state) {
            state.status = '';
            state.token = '';
        }
    },
    getters: {
        financialYear(state){
          return state.financialYear;  
        },
        financialBudgetYears(state){
            return state.financialBudgetYears;
        },
        financialStatementYears(state){
            return state.financialStatementYears;
        },
        financialQuarterReportYears(state){
            return state.financialQuarterReportYears;
        },
        sblPriest: (state) => {
            return state.sbl_priest;
        },
        electionType: (state) => {
            return state.electionType;
        },
        hasElectionAdminGlobalRole: (state) => {
            return state.userRoles && (state.userRoles.globalAccessRoles & AccessRoleTypes.ElectionAdminGlobal) === AccessRoleTypes.ElectionAdminGlobal;
        },
        getElectionYear: (state) => {
            return state.electionYear;
        },
        electionDocument: (state) => {
            return state.electionDocument;
        },
        electionBoardMembers: (state) => {
            return state.electionBoardMembers;
        },
        electionYearInfo: (state) => {
            return state.electionYearInfo;
        },
        electionEvents: (state) => {
            return state.electionEvents;
        },
        getActiveArea: (state) => {
            return state.area;
        },

        token: (state) => {
            return state.token;
        },
        userRoles: (state) => {
            return state.userRoles;
        },
        activeYear: (state) => {
            return state.activeYear;
        },
        activeUserRole: (state) => {
            return state.activeUserRole;
        },
        getUserInfo: state => {
            return state.userInfo;
        },
        getUserRoles: (state) => (level: Array<OrganizationLevelTypes>) => {
            return lodash.sortBy(state.userRoles.userRoles.filter((r: any) => level.indexOf(r.level) > -1), "groupName");
        },
        getMyMenighedsraad: (state, getters) => {
            return getters.getUserRoles([OrganizationLevelTypes.Menighedsraad]);
        },
        getMyProvstier: (state, getters) => {
            return getters.getUserRoles([OrganizationLevelTypes.Provsti]);
        },
        getMyKirkegaardeAndOthers: (state, getters) => {
            return getters.getUserRoles([OrganizationLevelTypes.Kirkegaard,OrganizationLevelTypes.Other]);
        },
        getMyStifter: (state, getters) => {
            return getters.getUserRoles([OrganizationLevelTypes.Stift]);
        },
        years: (state) => {
            return state.years;
        },
        getActiveModule: (state) => {
            return state.module;
        },
        getDataLoading: (state) => {
            return state.dataLoading;
        },
        getMembersDataLoading: (state) => {
            return state.membersDataLoading;
        },
        getSblDiocseSettings: (state) => {
            return state.sblDioceseSettings;
        },
        aggregatedCongregations: (state) => {
            return state.aggregatedCongregations;
        }
    }
});