<template>
    <div class="card border-0"></div>
</template>

<script>
    
    export default {
        name: "PortalModuleEmptyCard",
        data(){
          return {
          }  
        },
        computed:{
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>