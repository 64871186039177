<template>
    <div class="alert alert-light shadow-sm border text-black mt-4">
        <p class="lead mb-0"></p>

        <p><strong>Kirkeportalen</strong> indeholder data, funktioner og programmer som især anvendes af menighedsrådene.</p>
        
        <p>
            Menighedsrådene skal anvende kirkeportalen i forbindelse med aflevering af budget for 2023 og i forbindelse med aflevering af regnskaber og materialer vedrørende regnskabet for 2022.
        </p>

        <p>
            Provstierne har i Kirkeportalen adgang til et overblik med hensyn til menighedsrådenes aflevering af budgetter og regnskaber – herunder aflevering af kvartalsrapporter.
        </p>

        <p>
            Såfremt et menighedsråd har behov for at erstatte et allerede afleveret budget eller regnskab med en ny udgave, skal provstiet kontaktes. Tilsvarende gælder en ny udgave af en beslutningsprotokol, som indeholder godkendelse af et budget eller eller et regnskab samt kvartalsrapporter.
        </p>

        <p>
            I disse tilfælde kan provstiet åbne for genaflevering af det dokument, der skal erstattes. Når provstiet har fjernet det ønskede dokument kan det nye dokument uploades.
        </p>

        <p><a href="https://support.kirkenettet.dk/hc/da/articles/4423834638098" target="_blank">Læs mere om Kirkeportalen her.</a></p>

        <em>Indtil alle funktioner i det nuværende Dataarkiv er omlagt til Kirkeportalen vil Dataarkivet stadig være tilgængeligt.</em>

    </div>
</template>

<script>
    
    export default {
        name: "HomeInfo",
        data(){
          return {
          }  
        },
        computed:{
        },
        created() {
        },
        methods: {
        
        }
    }
</script>

<style scoped>

</style>