import { ElectionDocumentInfo } from "@/services/Model/Election/ElectionDocumentInfo";
import { ParishInfo } from "@/services/Model/Election/ParishInfo";
import lodash from "lodash";

export class ElectionMaterialResolver {
    public static readonly PARISHWHITELISTCOLLECTION: Array<number> = [ ];

    public static ElectionMaterialAvailable = (electionDocument: ElectionDocumentInfo): boolean => {
        // Arrange
        if(!electionDocument || !electionDocument.parishCollection)
            return false;
        // Act
        var parishInWhitlist = lodash.some(electionDocument.parishCollection, (parish: ParishInfo) => lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parish.parishId));
        
        return electionDocument.electionYear === 2024 || parishInWhitlist;
    }

    public static ElectionMatchElectionYear = (electionDocument: ElectionDocumentInfo): boolean => {
        return electionDocument.electionYear === 2024;
    }

    public static GetParishWhitelisted = (electionDocument: ElectionDocumentInfo): ParishInfo[] => {
        return lodash.filter(electionDocument.parishCollection, (parish: ParishInfo) => lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parish.parishId));
    }

    public static ParishIdIsWhiteListed = (parishId: number): boolean => {
        return lodash.includes(ElectionMaterialResolver.PARISHWHITELISTCOLLECTION, parishId);
    }
}