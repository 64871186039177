<template>
    <div>
        <slot></slot>
    </div>
</template>

<script langt="ts">


    export default {
        name: "DefaultLayout",
        data(){
            return {
                
            }
        }
    }
</script>

<style scoped>
</style>