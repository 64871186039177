enum EventCodeType {
    InputMembersSaved = 100,

    InvitesSent = 200,

    ElectionComplaintReceived   = 300,
    ElectionComplaintCancelled  = 301,

    InputMemberDelegated = 400,

    MaterialPrinted = 500,

    DecisionProtocolMrCreated = 505,

    DecisionProtocolExtraMrCreated = 506,
    DecisionProtocolElectoralVotingUploaded = 510,
    DecisionProtocolFillVotingUploaded = 520,

    // Settings: [600-699]
    SettingsElectionYearUpdate = 600,

    ParishElectionStateChanged = 700,

    ParishElectionModeChanged = 800,

    ParishElectionModeExtraChanged = 801,

    ConstitutionAccepted = 1000,
    DAPBrugerAdministrationAccepted = 1001
}

export default EventCodeType;