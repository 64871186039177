enum OrganizationLevelTypes {
    Stift = 1,
    Provsti = 2,
    Sogn = 3,
    Menighedsraad = 4,
    Kirkegaard = 5,
    Other = 100
    

}
export default OrganizationLevelTypes;

