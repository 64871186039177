import AccessRoleTypes from './AccessRoleTypes';


export default class UserGroupHelper {
    public static ContainsRole = (userRoles: AccessRoleTypes, userRoleToTest: AccessRoleTypes) => 
    {
        return (userRoles & userRoleToTest) === userRoleToTest;
    }
}

