<template>
    <div v-if="!hasRoleRequired">
        <div class="alert alert-warning" role="alert">
            <p class="lead mb-0">
                <i class="fal fa-lightbulb-exclamation"></i> Du har ikke rettighed til at tilgå valgsystemet for det valgte menighedsråd.
            </p>

            <a href="https://support.kirkenettet.dk/#/item/238" target="_blank" class="btn btn-link pl-0">Læs om adgang og tildeling af adgang til Valgsystemet her</a>

            <button class="btn btn-link py-0" v-if="!isElectionHome" @click=navigateElectionHome title="Gå tilbage">Tilbage</button>
            
        </div>

    </div>
</template>

<script>
    import ElectionNavigationMixin from "../../mixins/ElectionNavigationMixin";

    export default {
        name: "ElectionRightsBox",
        mixins : [ElectionNavigationMixin],
        computed:{
            isElectionHome()
            {
                return this.$route.name === "ElectionHomeModule";
            }
        }
        
    }
</script>

<style scoped>
button{
    display:block;
}
</style>