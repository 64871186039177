import Router from 'vue-router';
import LoginNewView from './views/LoginNew.vue';
import ElectionHome from './views/Election/Home.vue';
import PortalHome from './views/Portal/Home.vue';
import StiftPortalHome from './views/Stift/Home.vue';

import authService from "./services/authService";
import ElectionType from "@/services/Model/Election/ElectionType";
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";
import budgetRoutes from '@/Routes/budget';

export default new Router({
    mode: 'history',
    routes: [
        
        {
            path: '/login',
            component: LoginNewView,
            
        },
        {
            path: '/logout',
            meta: { pageTitle: 'Log out', requireAuth: true },
            component: () => {
                authService.logOut();
            }
        },
        {
            path: '/:mid?',
            name: 'PortalModule',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: PortalHome
        },
        {
            path: '/mr/:mid?',
            name: 'MRHome',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/MRHome.vue')
        },
        {
            path: '/ot/:mid?',
            name: 'KirkegaardHome',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other] },
            props: true,
            component: () => import( './views/Kirkegaard/KirkegaardHome.vue')
        },
        {
            path: '/ot/:mid/info',
            name: 'PortalKirkegaardInfo',
            meta: { pageTitle: 'Vejledninger til kirkeportalen og cirkulærer m.v. om sikkerhed', hideBackButton: false, requireAuth: true, layout: 'portal',level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Portal/Info.vue')
        },
        {
            path: '/ot/:mid/KasGias/menu',
            name: 'KgKasGiasMenu',
            meta: { pageTitle: 'Gravstedsaftaler og kapitaler', hideBackButton: false, requireAuth: true, layout: 'portal',level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Portal/KasGiasMenu.vue')
        },
        {
            path: '/sogn/:mid?',
            name: 'SognHome',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Sogn] },
            props: true,
            component: PortalHome
        },
        {
            path: '/mr/:mid/info',
            name: 'PortalMrInfo',
            meta: { pageTitle: 'Vejledninger til kirkeportalen og cirkulærer m.v. om sikkerhed', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Portal/Info.vue')
        },
        {
            path: '/mr/:mid/KasGias/menu',
            name: 'KasGiasMenu',
            meta: { pageTitle: 'Gravstedsaftaler og kapitaler', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Portal/KasGiasMenu.vue')
        },
        {
            path: '/stift/:mid?',
            name: 'StiftHome',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: true,
            component: StiftPortalHome
        },        
        {
            path: '/mr/:mid/brugere',
            name: 'PortalBrugereRettighederMenu',
            meta: { pageTitle: 'Brugere og rettigheder', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import( './views/Menighedsraad/BrugereRettighederMenu.vue'),
        },
        {
            path: '/ot/:mid/brugere',
            name: 'OtherBrugereRettighederMenu',
            meta: { pageTitle: 'Brugere og rettigheder', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Kirkegaard/BrugereRettighederMenu.vue'),
        },
        {
            path: '/ot/:mid/brugere/opret',
            name: 'OtherBrugerOpret',
            meta: { pageTitle: 'Brugeroprettelse', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Menighedsraad/BrugerOpret.vue'),
        },
        {
            path: '/ot/:mid/firma/opret',
            name: 'OtherFirmaOpret',
            meta: { pageTitle: 'Medarbejderoprettelse', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Menighedsraad/FirmaOpret.vue'),
        },
        {
            path: '/mr/:mid/brugere/rettigheder',
            name: 'PortalBrugereRettigheder',
            meta: { pageTitle: 'Bruger adgange', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/BrugereRettigheder.vue'),
        },
  
        {
            path: '/mr/:mid/brugere/opret',
            name: 'PortalBrugerOpret',
            meta: { pageTitle: 'Brugeroprettelse', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/BrugerOpret.vue'),
        },
        {
            path: '/mr/:mid/firma/opret',
            name: 'PortalFirmaOpret',
            meta: { pageTitle: 'Medarbejderoprettelse', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/FirmaOpret.vue'),
        },
        {
            path: '/mr/:mid/databehandleraftaler',
            name: 'Databehandleraftaler',
            meta: { pageTitle: 'Databehandleraftaler m.m.', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/DatabehandlerAftaler/DatabehandlerAftalerHome.vue'),
        },
        {
            path: '/mr/:mid/GLNtilslutning',
            name: 'GLNTilslutning',
            meta: { pageTitle: 'GLNTilslutning', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Menighedsraad/DatabehandlerAftaler/GLNTilslutning.vue'),
        },
        {
            path: '/provsti/:mid/GLNProvstiTilslutning',
            name: 'ProvstiGLNTilslutning',
            meta: { pageTitle: 'GLNTilslutning', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            props: true,
            component: () => import('./views/Provsti/DatabehandlerAftaler/GLNProvstiTilslutning.vue'),
        },
        {
            path: '/provsti/:mid/databehandleraftaler',
            name: 'DatabehandleraftalerProvsti',
            meta: { pageTitle: 'Databehandleraftaler m.m.', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Provsti/DatabehandlerAftaler/DatabehandlerAftalerHome.vue'),
        },
        // {
        //     path: '/',
        //     redirect: '/valg/2020'
        // },

        // Section: Dokumentarkiv
        {
            path: '/mr/:mid/dokumentarkiv/budget',
            name: 'DokumentBudgetStart',
            meta: { pageTitle: 'Budget- og regnskabsarkiv', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            props: true,
            component: () => import('./views/Dokumentarkiv/DocumentShareView.vue'),
        },
        {
            path: '/ot/:mid/dokumentarkiv/budget',
            name: 'KirkegaardDokumentBudgetStart',
            meta: { pageTitle: 'Budget- og regnskabsarkiv', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other] },
            props: true,
            component: () => import('./views/Dokumentarkiv/DocumentShareView.vue'),
        },
        {
            path: '/provsti/:mid/dokumentarkiv/budget',
            name: 'ProvstiDokumentBudgetStart',
            meta: { pageTitle: 'Budget- og regnskabsarkiv', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            props: true,
            component: () => import('./views/Dokumentarkiv/DocumentShareView.vue'),
        },
        
        // Section: Valg
        {
            path: '/valg',
            redirect: '/valg/2020'
        },
        {
            path: '/valg/admin/',
            name: 'ElectionAdmin',
            meta: { requireAuth: true, admin: true },
            component: () => import('./views/Election/Admin/Home.vue')
        },
        {
            path: '/valg/admin/results/mr/',
            name: 'MRResultsOverview',
            meta: { requireAuth: true, admin: true },
            component: () => import('./views/Election/Admin/Results/MROverview.vue')
        },
        {
            path: '/mr/:mid?/valg/:period',
            name: 'ElectionHomeModule',
            meta: { pageTitle: 'Oversigt', hideBackButton: true, requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            component: ElectionHome
        },
        {
            path: '/mr/:mid/valg/:period/board/edit',
            name: 'ElectionBoardEditModule',
            meta: { pageTitle: 'Angiv antal medlemmer', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import(/* webpackChunkName: "about" */ './views/Election/Board/Edit.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/board/howto',
            name: 'ElectionBoardHowtoModule',
            meta: { pageTitle: 'Hvordan bruges Valgsystemet?', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import('./views/Election/Board/Howto.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/board/material',
            name: 'ElectionBoardMaterialModule',
            meta: { pageTitle: 'Hent valgliste m.m.', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import(/* webpackChunkName: "about" */ './views/Election/Board/Material.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/board/communication',
            name: 'ElectionBoardCommunicationModule',
            meta: { pageTitle: 'Udsend indbydelser', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import(/* webpackChunkName: "about" */ './views/Election/Communication/Communication.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/board/settings',
            name: 'ElectionBoardSettingsModule',
            meta: { pageTitle: 'Indstillinger for valg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import(/* webpackChunkName: "about" */ './views/Election/Board/Settings.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/assembly/:pageElectionType',
            name: 'ElectionAssemblyModule',
            meta: { pageTitle: 'Valgforsamling', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: (route) => { return { period: parseInt(route.params.period), mid: route.params.mid, accessLevel: OrganizationLevelTypes.Menighedsraad, step: 0 } },
            component: () => import('./views/Election/ElectionAssembly.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/assembly/:pageElectionType/:pids?/step/:step?',
            name: 'ElectionAssemblyStep',
            meta: { pageTitle: 'Valgforsamling', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: (route) => {
                //TODO: @Pel: Er det muligt at trigger event ved direkte link. (mixin-move) ?
                // this.$root.$emit('stepChanged',route.params.step); <-- like this
                return {
                    period: parseInt(route.params.period),
                    mid: route.params.mid,
                    accessLevel: OrganizationLevelTypes.Menighedsraad,
                    step: +route.params.step,
                    pageElectionType: route.params.pageElectionType
                }
            },
            component: () => import(/* webpackChunkName: "about" */ './views/Election/ElectionAssembly.vue'),
        },
        {
            path: '/stift/:mid/:period/list',
            name: 'ElectionStiftListModule',
            meta: { pageTitle: 'Stiftsoverblik', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/Election/Stift/List.vue'),
        },
        {
            path: '/stift/:mid/checkmembership',
            name: 'SblStiftCheckModule',
            meta: { pageTitle: 'Folkekirkemedlemskab', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/CheckMembership.vue'),
        },
        {
            path: '/stift/:mid/BudgetRegnskab/menu',
            name: 'StiftBudgetRegnskabMenuModule',
            props: true,
            meta: { pageTitle: 'Provstiernes budget og regnskab​', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            component: () => import('./views/Stift/StiftBudgetOgRegnskab.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/afstemningsvalg',
            name: 'electionCandidateModule',
            meta: { pageTitle: 'Afstemningsvalg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import('./views/Election/ElectionCandidate.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/udfyldningsvalg',
            name: 'electionCandidateFillModule',
            meta: { pageTitle: 'Udfyldningssvalg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import('./views/Election/ElectionCandidateFillElection.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/:parishId/afstemningsvalg/resultater',
            name: 'electionParishVotingResultModule',
            meta: { pageTitle: 'Resultat fra afstemningsvalg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad },
            component: () => import('./views/Election/ElectionCandidateVotingResults.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/:parishId/fillvote/results',
            name: 'electionParishFillVotingResultModule',
            meta: { pageTitle: 'Resultat fra udfyldningsvalg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad, FillElectionType : ElectionType.FILLVOTE },
            component: () => import('./views/Election/ElectionCandidateFillElectionResults.vue'),
        },
        {
            path: '/mr/:mid/valg/:period/:parishId/xtraordinaryfillvote/results',
            name: 'electionParishXtraordinaryFillVotingResultModule',
            meta: { pageTitle: 'Resultat fra ekstraordinært udfyldningsvalg', requireAuth: true, layout: 'election', level: [OrganizationLevelTypes.Menighedsraad] },
            props: { accessLevel: OrganizationLevelTypes.Menighedsraad, FillElectionType : ElectionType.EXTRAORDINARYFILLVOTE },
            component: () => import('./views/Election/ElectionCandidateFillElectionResults.vue'),
        },
       
        // SBL
        
        {
            path: '/stift/:mid/sbl',
            name: 'SblStiftListModule',
            meta: { pageTitle: 'SBL Administration', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            beforeEnter: (to, from, next) => {
                const externalUrl = `https://persontjek.kirkenettet.dk/#/stift/${to.params.mid}/sbl`;
                window.location.href = externalUrl;
            }
        },
        {
            path: '/stift/:mid/sbl/get',
            name: 'SblStiftGetModule',
            meta: { pageTitle: 'Hent SBL-aftaler', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/Get.vue'),
        },
        {
            path: '/stift/:mid/sbl/view',
            name: 'SblStiftViewModule',
            meta: { pageTitle: 'Frems\u00f8gte SBL-aftaler', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/View.vue'),
        },
        {
            path: '/stift/:mid/sbl/genbesat',
            name: 'SblStiftResolveModule',
            meta: { pageTitle: 'Stilling genbesat', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/Resolve.vue'),
        },
        {
            path: '/stift/:mid/sbl/udskyd',
            name: 'SblStiftExtendModule',
            meta: { pageTitle: 'Uds\u00e6t oph\u00f8rstidspunkt', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/Extend.vue'),
        },
        {
            path: '/stift/:mid/sbl/arkiv',
            name: 'SblStiftArchiveModule',
            meta: { pageTitle: 'Arkiv', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/Archive.vue'),
        },
        {
            path: '/stift/:mid/sbl/resolve',
            name: 'SblStiftResolveOneModule',
            meta: { pageTitle: 'Luk en enkelt aftale', requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            props: { accessLevel: 1 },
            component: () => import('./views/SBL/ResolveOne.vue'),
        },

      
        
        // REGNSKAB:
        {
            path: '/mr/:mid/Regnskab',
            name: 'RegnskabModule',
            props: true,
            meta: { pageTitle: 'Regnskab', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
            component: () => import('./views/Menighedsraad/Regnskab.vue'),
        },
        {
            path: '/ot/:mid/BudgetRegnskab/menu',
            name: 'KirkegaardBudgetRegnskabMenuModule',
            props: true,
            meta: { pageTitle: 'Kirkegårdenes budget og regnskab​', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other]},
            component: () => import('./views/Kirkegaard/KirkegaardBudgetOgRegnskab.vue'),
        },
      
        {
            path: '/ot/:mid/Regnskab',
            name: 'KirkegaardRegnskabModule',
            props: true,
            meta: { pageTitle: 'Regnskab', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other]},
            component: () => import('./views/Menighedsraad/Regnskab.vue'),
        },
        
        {
            path: '/provsti/:mid?',
            name: 'ProvstiHome',
            meta: { pageTitle: 'Forside', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            props: true,
            component: () => import('./views/Provsti/ProvstiHome.vue')
        },
        {
            path: '/provsti/:mid/BudgetRegnskab/menu',
            name: 'ProvstiBudgetRegnskabMenuModule',
            props: true,
            meta: { pageTitle: 'Provstiernes budget og regnskab​', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            component: () => import('./views/Provsti/ProvstiBudgetOgRegnskab.vue'),
        },
        {
            path: '/provsti/:mid/Regnskab',
            name: 'ProvstiRegnskabModule',
            props: true,
            meta: { pageTitle: 'Regnskab', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            component: () => import('./views/Provsti/ProvstiRegnskab.vue'),
        },
       
        {
            path: '/provsti/:mid/Regnskab/Overblik',
            name: 'ProvstiRegnskabOverblikModule',
            props: true,
            meta: { pageTitle: 'Provstiets regnskaber', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
            component: () => import('./views/Provsti/RegnskabList.vue'),
        },
        {
            path: '/stift/:mid/Regnskab/Overblik',
            name: 'StiftRegnskabOverblikModule',
            props: true,
            meta: { pageTitle: 'Stiftets kirekasser', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
            component: () => import('./views/Stift/StiftRegnskabList.vue'),
        },
        {
            path: '/provsti/:mid/info',
            name: 'PortalProvstiInfo',
            meta: { pageTitle: 'Vejledninger til kirkeportalen og cirkulærer m.v. om sikkerhed', hideBackButton: false, requireAuth: true, layout: 'portal',level: [OrganizationLevelTypes.Provsti] },
            props: true,
            component: () => import('./views/Portal/Info.vue')
        },
        ...budgetRoutes
    ]
});
