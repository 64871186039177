<template>
    <div class="card shadow-sm card-hover" :onclick="'window.open(\'' + link + '\', \'_blank\');'" style="cursor: pointer;">
      
      <template v-if="iconClass">

        <div class="card-header text-center m-2" style="background-color: #495057; min-height: 75px;">
          <i class="fad fa-3x" style="color: white; --fa-secondary-opacity: 0.40" :class="iconClass"></i>
        </div>
        
      </template>
      <template v-if="!iconClass" >
        <div class="card-header text-center m-2 py-0" style="background-color: #495057; min-height: 10px;">
        </div>
      </template>
        <div class="card-body border-top highlight">
            <b-overlay :show="false" :opacity="0.4" spinner-type="null">
                <h6 class="card-subtitle mb-2">{{ title }}</h6>

                <div class="d-flex justify-content-between">
                    <p class="card-text" v-html="description">
                        
                    </p>
                </div>
                <br />
                <div class="d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-outline-dark btn-block mt-0"><i class="far fa-arrow-up-right-from-square"></i> {{ buttonText }}</button>
                </div>
            </b-overlay>
        </div>
      <div v-if="footer" class="card-footer">
        <small class="text-muted" v-html="footer"></small>
      </div>
    </div>

</template>

<script>
    
    export default {
        name: "PortalModuleLinkCard",
        props :['title','description','buttonText', 'link','iconClass','footer'],
        data(){
          return {
          }  
        },
        computed:{
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>