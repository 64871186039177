<template>
<span v-if="hasElectionAdminGlobalRole">
  <button v-on:click="setMidExplicit" class="btn btn-light"
          v-b-popover.hover.bottom="" title="Valgsupport">
      <i class="fad fa-user-crown"></i>
  </button>
  <b-link class="btn btn-light mr-2" v-b-popover.hover.auto=""
          :to="{name: 'ElectionAdmin'}" title="Gå til valg Admin" style="width:40px">
      <i class="fas fa-cog"></i> 
  </b-link>
     
</span>
</template>
<script>
import bootbox from 'bootbox';
import ElectionNavigationMixin from "@/mixins/ElectionNavigationMixin";
import APIService from "@/services/apiservice";
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";
import AuthorityEntityLookupService from "@/services/AuthorityEntityLookupService";

export default {
  name: 'SwitchAuthority',
  mixins: [ElectionNavigationMixin],
  methods: {
    setMidExplicit() {
      bootbox.prompt("Indtast MID", (newMid) => {
        
        //lookup authority type based on
        
        AuthorityEntityLookupService.Lookup(newMid).then(r => {

            let level = r.data.level;
            
            switch (level) {
              case OrganizationLevelTypes.Sogn:
              case OrganizationLevelTypes.Menighedsraad:
                name = "MRHome";
                break;
              case OrganizationLevelTypes.Provsti:
                name = "ProvstiHome";
                break;
              case OrganizationLevelTypes.Stift:
                name = "StiftHome";
                break;
              case OrganizationLevelTypes.Kirkegaard:
              case OrganizationLevelTypes.Other:
                name = "KirkegaardHome";
                break;
            }
            
            this.$router.push({name : name, params: {mid: newMid}});
            
            
          });
        }
      );
    }
  }
}
</script>
