<template>
    <div>
        <slot></slot>
    </div>
</template>

<script langt="ts">
    import { mapActions } from 'vuex'
    import moment from "moment";
    import PortalMixin from "@/mixins/PortalMixin";
    import store from "@/store"
    
    export default {
        name: "PortalLayout",
        mixins: [PortalMixin],
        data(){
            return {

            }
        },
      methods:{
        ...mapActions(["loadActiveUserRole"])  
      },
        watch: {
            midFromUrl(value) {
                this.checkAndSetGroupFromUrl();
            }
        },
        created: function () {
            store.dispatch('loadUserInfo');
            store.dispatch('loadUserRoles');
            store.dispatch("clearElectionDocument");
            store.commit("setActiveArea", "Kirkeportal");
            store.commit("setActiveModule", "Kirkeportal");
            store.dispatch('loadUserRoles').then(() => {
                
            });

            const __months = moment.months().map(function (m) {
                return m.charAt(0).toUpperCase() + m.slice(1);
            });

            const __monthsShort = moment.monthsShort().map(function (m) {
                return m.charAt(0).toUpperCase() + m.slice(1);
            });

            moment.updateLocale("da", { months: __months, monthsShort: __monthsShort });
          
            if(this.midFromUrl !== ""){
              this.checkAndSetGroupFromUrl();
          }

        },
    }
    
</script>

<style scoped>
</style>