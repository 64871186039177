import {mapActions, mapGetters, mapMutations} from 'vuex';
import UserGroupHelper from "@/services/UserGroupHelper";
import AccessRoleTypes from "@/services/AccessRoleTypes";
import ElectionYearService from "@/services/ElectionYearService";

export default {
    props:{
        accessLevel : Number
    },
    watch:{
        activeUserRole(value,oldvalue){
            
            if(oldvalue.groupValue === "")
                return;
            
            if(this.accessLevel && value.level !== this.accessLevel)
            {
                this.navigateElectionHome();
            }
        }
    },
    computed: {
        userIsMenigtMedlem() {
            return UserGroupHelper.ContainsRole(this.activeUserRole.accessRoles, AccessRoleTypes.MenigtMedlem);
        },
        hasRoleRequired() {
            if (this.activeUserRole == null || this.activeUserRole === {})
                return false;
            
            return UserGroupHelper.ContainsRole(this.activeUserRole.accessRoles, AccessRoleTypes.ElectionBoard) || this.hasElectionAdminGlobalRole || this.hasStiftAccess;
        },
        hasStiftAccess(){
            if(!this.electionDocument)
                return false;

            return (this.userRoles.userRoles.some(u => +u.groupValue === this.electionDocument.diocese.identifier && UserGroupHelper.ContainsRole(+u.accessRoles, AccessRoleTypes.ElectionBoard)));
        },
        pageTitle() {
            return this.$route.meta.pageTitle;
        },
        hideBackButton() {
            return this.$route.meta.hideBackButton;
            
        },
        midFromUrl() {
            if(!this.$route.params.mid)
            {
                if(this.$store.getters.activeUserRole)
                    return this.$store.getters.activeUserRole.groupValue;
                else
                    return "";
            }
                
            return this.$route.params.mid.toString();
        },
        ...mapGetters([
            "userRoles",
            "getUserInfo",
            "activeUserRole",
            "electionYearInfo", 
            "getElectionYear",
            "electionDocument",
            "electionBoardMembers",
            "electionEvents",
            "getDataLoading",
            "getMembersDataLoading",
            "hasElectionAdminGlobalRole",
            "getMyStifter"])
    },
    methods: {
        ...mapMutations(["setElectionDocument"]),
        ...mapActions([
            "loadElectionEvents",
            "loadElectionDocument",
            "loadElectionBoardMembers",
            "loadElectionYearInfo"]),
        loadEvents() {
            this.loadElectionEvents({year : this.getElectionYear, mid : this.midFromUrl});
        },
        loadDocument() {
            this.loadElectionDocument({year : this.getElectionYear,mid : this.midFromUrl,route : this.$route});
        },
        loadElectionYear() {
            this.loadElectionYearInfo(this.getElectionYear);
        },
        loadBoardMembers() {
            this.loadElectionBoardMembers(this.electionDocument);
        },
        checkAndSetGroupFromUrl() {
            if (this.midFromUrl) 
                ElectionYearService.LoadElectionYearAndDocument(this.midFromUrl,this.$route,this.loadDocument);
        },
        navigateElectionHome() {
            if (this.$route.name === "ElectionHomeModule")
                return;

            if (this.midFromUrl)
                this.$router.push({
                    name: "ElectionHomeModule",
                    params: {period: this.getElectionYear, mid: this.midFromUrl}
                });
            else
                this.$router.push({name: "ElectionHomeModule", params: {period: this.getElectionYear}});
        },
    }
}