export const msalConfig = {
    auth: {
        tenantId : "791b6229-ec70-46d0-a538-53a2a40ced78",
        clientId: "bcaacae6-cb4e-49c5-bd91-cfac83fedc20", 
        authority: "https://login.microsoftonline.com/791b6229-ec70-46d0-a538-53a2a40ced78",
        redirectUri: __REDIRECT_URL__
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
};

export const apiConfig = {
     scopes: ["api://bc46454f-317d-40eb-bd5d-27e0e8924c25/access_as_user"],
};
