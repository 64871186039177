























































































































































import store from './store';
import groupPicker from './components/GroupMembershipDropDown.vue';
import EnvironmentBadge from './components/EnvironmentBadge.vue';
import {mapGetters} from 'vuex';
import UserGroupHelper from './services/UserGroupHelper';
import AccessRoleTypes from './services/AccessRoleTypes';
import ElectionNavigationMixin from "@/mixins/ElectionNavigationMixin";
import PortalMixin from "@/mixins/PortalMixin";

    const axios = require('axios'); 
    import SwitchAuthority from "@/SwitchAuthority.vue";
    

    const default_layout = "default";

    export default {
        mixins: [ElectionNavigationMixin, PortalMixin],
        data() {
            return {
                environment: process.env.NODE_ENV.trim(),
                apiData: null
            }
        },
        name: 'ElectionHome',
        components: {
            EnvironmentBadge,
            SwitchAuthority,
            GroupPicker: groupPicker
        },
        computed: {
            ...mapGetters(["getElectionYear"]),
            shownGroupIdentifier() {
                if (this.activeUserRole.level === 3 && this.electionDocument)
                    return this.electionDocument.mid;
                else
                    return this.activeUserRole.groupValue;

            },
            shownGroupName() {
                if (this.activeUserRole)
                    return this.activeUserRole.groupName;

                return '';

            },
            layout() {
                console.info("layout",this.$route.meta.layout,(this.$route.meta.layout || default_layout) + '-layout');
                return (this.$route.meta.layout || default_layout) + '-layout';
            },
            ...mapGetters([
                "getElectionYear",
                "getUserInfo",
                "activeUserRole",
                "activeYear",
                "getActiveModule",
                "getActiveArea",
                "userRoles"
            ])
        },
        methods: {
            hasRole: (accessRole: AccessRoleTypes) => {
                if (store.getters.activeUserRole == null || store.getters.activeUserRole === {})
                    return false;

                return UserGroupHelper.ContainsRole(store.getters.activeUserRole.accessRoles, accessRole);
            }

        }
    }
