<template>
        <div class="card shadow-sm card-hover" :class="{ 'border-light' : !(cardActive || false), 'border-enabled' : (cardActive || false) }">
            <div class="card-body">
                <b-overlay :show="!(cardActive || false)" :opacity="0.4" spinner-type="null">
                <div class="text-center pd-2 pr-2" v-on:click="navigate" style="cursor: pointer;">
                    <i :class="cardIconClass"></i>
                    <h5>{{title}}</h5>
                    <hr class="highlight" />  
                </div>
                <div class="d-flex justify-content-between" style="min-height: 64px;">
                    <p class="card-text" v-bind:class="{ 'text-truncate' : !readMore }" v-html="description"></p>
<!--                    <a class="card-link text-nowrap" href="#" v-on:click.prevent="readMore = true" v-show="!readMore">Læs mere</a>-->
                </div>

                <div class="d-flex justify-content-between align-items-center mt-2">
                    <router-link :to="route" class="btn btn-outline-dark btn-block"><i :class="buttonIconClass"></i> {{buttonText}}</router-link>
                </div>
                </b-overlay>
            </div>
            <div class="card-footer">
                <slot name="footer"></slot>
            </div>
        </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import lodash from 'lodash';
    import moment from 'moment';
    
    export default {
        name: "ElectionModuleCard",
        props :['cardIconClass','title','description','buttonText','route','buttonIconClass', 'cardActive'],
        data(){
          return {
              readMore : true,
              lodash: lodash,
              moment: moment
          }  
        },
        computed:{
            ...mapGetters(["electionEvents","electionYearInfo"])
        },
        created() {
        },
        methods: {
            navigate() {
                this.$router.push(this.route);
            }
        }
    }
</script>

<style scoped>

</style>