<template>
    <div class="card shadow-sm card-hover" :class="{ 'border-light' : !(cardActive || false), 'border-enabled' : (cardActive || false) }">
        <div class="card-body">
            <b-overlay :show="!(cardActive || false)" :opacity="0.4" spinner-type="null">
            <div class="text-center pd-2 pr-2" v-on:click="navigate" style="cursor: pointer;">
                <i class="fad fa-3x fa-icon-highlight-light" :class="iconClass"></i>
                <h5>{{title}}</h5>
                <hr class="highlight" />
            </div>
            <div class="d-flex justify-content-between" style="min-height: 64px;">
                <p class="card-text" v-html="description"></p>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-2">
                <router-link :to="computedRoute" class="btn btn-outline-dark btn-block"><i class="fal" :class="iconClass"></i> {{buttonText}}</router-link>
            </div>
            </b-overlay>
        </div>
        <div class="card-footer">
            <span class="text-muted" v-html="footer"></span>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "PortalModuleElectionYearCard",
    props: ['iconClass', 'title', 'description', 'buttonText', 'footer', 'route', 'cardActive'],
    computed: {
        ...mapGetters(["getElectionYear"]),
        computedRoute() {
            this.$set(this.route.params, 'period', `${this.getElectionYear}`);
            return this.route;
        }
    },
    methods: {
        navigate() {
            this.$router.push(this.route);
        }
    }
}
</script>