<template>
    <election-module-card title="Afstemningsvalg" card-icon-class="fad fa-ballot-check fa-3x"
                          description="Her kan du arbejde med et evt. afstemningsvalg."
                          button-icon-class="fal fa-ballot-check"
                          button-text="Gå til afstemningsvalg"
                          :card-active="cardActive"
                          :route="route">
        <div slot="footer">
          &nbsp;    
        </div>
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";

    export default {
        name: "ElectionBoardVoteElectionCard",
        extends : ElectionModuleCard,
        components:{
            ElectionModuleCard
        }
    }
</script>

<style scoped>

</style>