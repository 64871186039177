<template>
    <election-module-card title="Hent valgliste m.m." card-icon-class="fad fa-file-download fa-3x"
                          description="Her henter du valglisten samt andet valg-materiale til brug under valgforsamlingen."
                          button-icon-class="fal fa-file-download"
                          button-text="Gå til hent valgliste m.m."
                          :card-active="cardActive"
                          :route="route">
        <div slot="footer" v-if="electionYearInfo != null">
            <small>
                <em v-show="cardActive">Valgliste(r) er tilgængelig fra d. {{moment(electionYearInfo.electionListExpectedAvailable).format('D. MMMM YYYY') }}</em>
                <!--<em>Testudgave af valgliste kan hentes indtil 8. september</em>-->
                <!--<em v-show="cardActive">Valglister ligger klar og kan hentes her</em>-->
                &nbsp;
            </small>
            <!--<small class="text-italic"
                   v-if="moment() > moment(electionYearInfo.electionListExpectedAvailable) && !lodash.some(electionEvents, (event) => { return event.eventCode === 500; })">Ingen udskrivninger.
            </small>-->
        </div>
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    import EventCodeType from "@/services/EventCodeType";
    import lodash from 'lodash';
    import moment from "moment";
    
    export default {
        name: "ElectionBoardMaterialCard",
        extends : ElectionModuleCard,
        data(){
            return {
                moment: moment,
                lodash: lodash,
                eventTypes : EventCodeType      
            }
        },
        props: {
            cardActive: Boolean
        },
        components:{
            ElectionModuleCard
        },
        computed:{            
        },
        created() {
        }
    }
</script>

<style scoped>

</style>