<template>
    <election-module-card title="Inviter til valgforsamling​" card-icon-class="fad fa-envelope-open-text fa-3x"
                          description="Her kan du sende indbydelser til stemme-berettigede personer via Digital post.​"
                          button-icon-class="fal fa-envelope-open-text"
                          button-text="Gå til inviter til valgforsamling​"
                          :card-active="cardActive"
                          :route="route">
        <div slot="footer" v-if="electionYearInfo != null">
            <!--<small v-if="lodash.some(electionEvents, (event) => { return event.eventCode === 200; })">
                Indbydelser udsendt d. {{moment(lodash.find(electionEvents, (event) => { return event.eventCode === 200; }).timestamp).format('D. MMMM YYYY')}}
            </small>
            <small class="text-italic"
                   v-if="!lodash.some(electionEvents, (event) => { return event.eventCode === 200; })">
                Åbnes 1. August.
            </small>-->

            <small class="text-muted text-italic">&nbsp;</small>
        </div>
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    import EventCodeType from "@/services/EventCodeType";
    import lodash from 'lodash';
    import moment from "moment";
    
    export default {
        name: "ElectionBoardCommunicationCard",
        extends : ElectionModuleCard,
        props: {
            cardActive: Boolean
        },
        data(){
            return {
                moment: moment,
                lodash: lodash,
                eventTypes : EventCodeType      
            }
        },
        components:{
            ElectionModuleCard
        },
        computed:{            
        },
        created() {
        }
    }
</script>

<style scoped>

</style>