





























import $ from "jquery";
import {mapGetters, mapState} from 'vuex';
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";

export default {

  props: {
    msg: String,
    classNames: String,
    btnStyle: String
  },
  data() {
    return {
      showPicker: false,
      selectedGroup: {}
    }
  },
  computed: {
    show(){
      return this.getUserRoles.length > 0;
    },
    ...mapGetters([
      "getUserInfo",
      "getMyMenighedsraad",
      "getMyProvstier",
      "getMyKirkegaardeAndOthers",
      "getMyStifter",
      "electionDocument",
      "getUserRoles"
    ]),
    ...mapState(["activeUserRole", "userRoles"])
  },
  watch: {
    selectedGroup(group: any) {
      let name = "";
      switch (group.level) {
        case OrganizationLevelTypes.Sogn:
        case OrganizationLevelTypes.Menighedsraad:
          group.level = OrganizationLevelTypes.Menighedsraad; //temp. fix 
          name = "MRHome";
          break;
        case OrganizationLevelTypes.Provsti:
          name = "ProvstiHome";
          break;
        case OrganizationLevelTypes.Stift:
          name = "StiftHome";
          break;
        case OrganizationLevelTypes.Kirkegaard:
        case OrganizationLevelTypes.Other:
          name = "KirkegaardHome";
          break;
      }
 
      if(this.$route.meta.level.indexOf(group.level) === -1 || (this.$route.meta.level.indexOf(group.level) > -1 && this.$route.params.mid !== group.groupValue))
        this.$router.push({name : name, params: {mid: group.groupValue}});

    },
    activeUserRole(value) {

      this.selectedGroup = value;
    }

  },
  updated: function () {
    $('.selectpicker').selectpicker('refresh');
  },
  mounted() {
    this.selectedMid = this.activeUserRole.groupValue.toString();
  }

}
