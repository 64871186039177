<template>
    <div>
        <slot></slot>
    </div>
</template>

<script langt="ts">
import moment from "moment";
import ElectionNavigationMixin from "@/mixins/ElectionNavigationMixin";
import store from "@/store";

export default {
        name: "ElectionLayout",
        mixins : [ElectionNavigationMixin],
        data(){
            return {

            }
        },
        watch: {
            electionDocument(documentValue){
              
             
                if (documentValue) 
                {
                    let path = this.$route;

                    if (+path.params.mid !== +documentValue.mid) {

                      if(!documentValue.aliases || documentValue.aliases.indexOf(+path.params.mid) === -1)
                      {
                        path.params.mid = documentValue.mid;
                        this.$router.push(path);
                      } 
                        
                    }
                    else {
                        this.$store.dispatch("loadActiveUserRole", this.$route);
                    }

                    this.loadEvents();
                    this.loadBoardMembers();
                }
                
                if(this.activeUserRole.groupName === "")
                    this.activeUserRole.groupName = documentValue.midName;

            },
            midFromUrl() {

                
                this.checkAndSetGroupFromUrl();

                
            },
            activeUserRole(value) {
                
                if (value.groupValue === "")
                    return;

                if(this.electionDocument && this.electionDocument.aliases.indexOf(+value.groupValue) > -1)
                    return;

                let path = this.$route;

                if (path.params.mid !== value.groupValue) {
                    path.params.mid = value.groupValue;
                    this.$router.push(path);
                }
            }
        },
        created: function () {
            store.dispatch('loadUserInfo');
            store.dispatch('loadUserRoles');  
            store.commit("setActiveArea", "Valg");
            store.commit("setActiveModule", "Valg");
            
            this.loadElectionYear();
            
            this.$store.dispatch('loadUserRoles').then(() => {

                const __months = moment.months().map(function (m) {
                    return m.charAt(0).toUpperCase() + m.slice(1);
                });

                const __monthsShort = moment.monthsShort().map(function (m) {
                    return m.charAt(0).toUpperCase() + m.slice(1);
                });

                moment.updateLocale("da", { months: __months, monthsShort: __monthsShort });
                
                
                if(this.midFromUrl !== ""){
                    this.loadDocument();                    
                }


            });


        },
    }
    
</script>

<style scoped>
</style>