<template>
    <div>
        <i :class="iconClass"></i> {{ title }} <span v-if="showGroupName" class="smaller">{{ groupName }} <small  class="text-muted">{{ groupIdentifier }} </small></span>
    </div>
</template>

<script>
    export default {
        
        name: "ElectionBoardDioceseTitle",
        props: {
            showGroupName : {
              type: Boolean,
              default : true
            },
            iconClass: String,
            title: String,
            groupName: String,
            groupIdentifier : String,

        },
        data() {
            return {
            }
        }
        
    }
</script>