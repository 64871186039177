<template>
    <election-module-card title="Hvordan bruges Valgsystemet?" card-icon-class="fad fa-person-sign fa-3x"
                          description="Valgforsamlingen kan afholdes med eller uden brug af pc – se her hvordan."
                          button-icon-class="fal fa-person-sign"
                          button-text="Gå til hvordan bruges Valgsystemet"
                          :card-active="cardActive"
                          :route="route">
        <div slot="footer">
            &nbsp;
        </div>
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    
    export default {
        name: "ElectionBoardHowtoCard",
        extends: ElectionModuleCard,
        props: {
            cardActive: Boolean
        },
        data(){
            return{
                
            }
        },
        components:{
            ElectionModuleCard
        },
        computed:{
        },
        created() {
        }
    }
</script>

<style scoped>

</style>