import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";

const budgetRoutes =
[
// MR:
{
    path: '/mr/:mid/BudgetRegnskab/menu',
    name: 'BudgetRegnskabMenuModule',
    props: true,
    meta: { pageTitle: 'Menighedsrådenes budget og regnskab', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
    component: () => import('@/views/Menighedsraad/BudgetOgRegnskab.vue'),
},
{
    path: '/mr/:mid/Budget',
    name: 'BudgetModule',
    props: true,
    meta: { pageTitle: 'Budget', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
    component: () => import('@/views/Menighedsraad/Budget.vue'),
},
{
    path: '/mr/:mid/BudgetStatus',
    name: 'BudgetListModule',
    props: true,
    meta: { pageTitle: 'Budget', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
    component: () => import('@/views/Provsti/BudgetList.vue'),
},
{
    path: '/mr/:mid/Kvartalsrapporter',
    name: 'QuarterReportModule',
    props: true,
    meta: { pageTitle: 'Kvartalsrapporter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
    component: () => import('@/views/Menighedsraad/QuarterReport.vue'),
}
//OTHER
,
    {
        path: '/ot/:mid/Budget',
        name: 'KirkegaardBudgetModule',
        props: true,
        meta: { pageTitle: 'Budget', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other]},
        component: () => import('@/views/Menighedsraad/Budget.vue'),
    },
    {
        path: '/ot/:mid/Kvartalsrapporter',
        name: 'KirkegaardQuarterReportModule',
        props: true,
        meta: { pageTitle: 'Kvartalsrapporter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Kirkegaard, OrganizationLevelTypes.Other] },
        component: () => import('@/views/Menighedsraad/QuarterReport.vue'),
    },
    
    //PROVSTI
    {
        path: '/provsti/:mid/Budget',
        name: 'ProvstiBudgetModule',
        props: true,
        meta: { pageTitle: 'Budget', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
        component: () => import('@/views/Provsti/ProvstiBudget.vue'),
    },
    {
        path: '/provsti/:mid/Kvartalsrapporter',
        name: 'ProvstiQuarterReportModule',
        props: true,
        meta: { pageTitle: 'Kvartalsrapporter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Menighedsraad] },
        component: () => import('@/views/Provsti/QuarterReport.vue'),
    },
    {
        path: '/provsti/:mid/Budget/Overblik',
        name: 'ProvstiBudgetOverblikModule',
        props: true,
        meta: { pageTitle: 'Provstiets budgetter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
        component: () => import('@/views/Provsti/BudgetList.vue'),
    },    
    {
        path: '/provsti/:mid/Kvartalsrapporter/Overblik',
        name: 'ProvstiQuarterReportOverblikModule',
        props: true,
        meta: { pageTitle: 'Provstiets kvartalsrapporter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Provsti] },
        component: () => import('@/views/Provsti/QuarterReportList.vue'),
    },

    //STIFT
    {
        path: '/stift/:mid/Budget/Overblik',
        name: 'StiftBudgetOverblikModule',
        props: true,
        meta: { pageTitle: 'Stiftets budgetter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
        component: () => import('@/views/Stift/StiftBudgetList.vue'),
    }, 
    {
        path: '/stift/:mid/Kvartalsrapporter/Overblik',
        name: 'StiftQuarterReportOverblikModule',
        props: true,
        meta: { pageTitle: 'Stiftets kvartalsrapporter', hideBackButton: false, requireAuth: true, layout: 'portal', level: [OrganizationLevelTypes.Stift] },
        component: () => import('@/views/Stift/QuarterReportList.vue'),
    }
    
]

export default budgetRoutes;