




export default {

    name: 'EnvironmentBadge',
    props: {
        environment: String,            
    },
    computed: {
        environmentName() {
            switch (this.environment) {
                case "development":
                    return "DEV";
                case "test":
                    return "TEST";
                default:
                    return "";
            }
        },
        isVisible() {
            return this.environment != 'production';
        }
    }
}
