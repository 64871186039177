import store from "@/store";
import AuthorityEntityLookupService from "@/services/AuthorityEntityLookupService";

export default class ElectionYearService {
    public static GetElectionYears = () =>{
        return [2020,2022,2024]
    }
    
    public static LoadElectionYearAndDocument = (mid : string, route : any, callbackFn : Function) => 
    {
        AuthorityEntityLookupService.Lookup(mid).then(() => {
            store.dispatch("loadActiveUserRole",route);
            store.dispatch("loadNewestElectionYear", mid).then(() => {
                window.setTimeout(() => callbackFn, 1000);
            });
        });
    }
}

