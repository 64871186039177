<template>
    <election-module-card title="Ekstraordinær valgforsamling" card-icon-class="fad fa-podium-star fa-3x"
                          description="Her kan du arbejde med en evt. ekstraordinære valgforsamling."
                          button-icon-class="fal fa-podium-star"
                          button-text="Gå til ekstraordinær valgforsamling"
                          :card-active="cardActive"
                          :route="route">
        
        <small slot="footer">
            <em class="text-muted">Åbnes ved ekstraordinær valgforsamling</em>
        </small>
        
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    import EventCodeType from "@/services/EventCodeType";
    import lodash from 'lodash';
    import moment from "moment";
    
    export default {
        name: "ElectionBoardAssemblyExtraCard",
        extends: ElectionModuleCard,
        props: {
            cardActive: Boolean
        },
        data(){
            return {
                moment: moment,
                lodash: lodash,
                eventTypes : EventCodeType      
            }
        },
        components:{
            ElectionModuleCard
        },
        computed:{            
        },
        created() {
        }
    }
</script>

<style scoped>

</style>