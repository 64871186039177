import axios from 'axios';
import CommandT from "@/services/ICommand";
import { CommandResponse }  from "@/services/CommandResponse";
import { CommandResponseTyped } from "@/services/CommandResponseTyped";
import store from "@/store";

export default class APIService {

    public static apiUrl = __API__;

    private static getConfig() {

        const config = {
            headers: {
                'Authorization': `Bearer ${store.getters.token.access_token}`,
            }
        };
        return config;
    }

    private static getAxios() {
        axios.defaults.baseURL = APIService.apiUrl;
        axios.interceptors.response.use(function (response) {
            return response;
        }, error => {
            console.info("error from interceptor",error.response.status,!window.location.pathname.startsWith('/login'))
            if(error.response.status === 401 && !window.location.pathname.startsWith('/login')) {
                window.sessionStorage.removeItem("token");
                window.location = "/login"
            }
        });

        return axios;
    }

    public static GET = (url: string) => {
        var ax = APIService.getAxios();
        
        return ax.get(`${url}`, APIService.getConfig());
    }

    public static GETBlob = (url: string) => {
        const ax = APIService.getAxios();
        return ax.get(`${url}`, {
            headers: {
                'Authorization': `Bearer ${store.getters.token.access_token}`,
            },
            responseType : 'blob'
        });
    }

    public static GETnoHeaders = (url: string) => {
        return axios.get(`${url}`);
    }

    public static PUT = (url: string, object: any, succesTitle?: string, successMessage?: string, errorTitle?: string, errorMessage?: string) => {
        var ax = APIService.getAxios();
        return ax.put(`${url}`, object, APIService.getConfig());
    }

    public static POST = (url: string, object: any, succesTitle?: string, successMessage?: string, errorTitle?: string, errorMessage?: string) => {
        var ax = APIService.getAxios();
        return ax.post(`${url}`, object, APIService.getConfig());
    }

    public static PostWithResponse = (url: string, data: any)/*: Promise<AxiosResponse<CommandResponse>>*/ => {
        var ax = APIService.getAxios();
        return ax.post<CommandResponse>(`${url}`, data, APIService.getConfig());
    }

    public static PATCH = (url: string, command: CommandT.ICommand) => {
        var ax = APIService.getAxios();
        return ax.patch(`${url}`, command, APIService.getConfig());
    }

    public static PostAsFile = (url: string, object: any) => {
        var ax = APIService.getAxios();
        var config = APIService.getConfig();
        config.responseType = 'blob';
        return ax.post(`${url}`, object, config);
    }

    public static DELETE = (url: string, succesTitle?: string, successMessage?: string, errorTitle?: string, errorMessage?: string) => {
        var ax = APIService.getAxios();
        return ax.delete(`${url}`, APIService.getConfig());
    }
    
}