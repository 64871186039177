<template>
    <div>
        <i :class="iconClass"></i> {{ title }} <span class="smaller">{{ shownGroupName }} <small class="text-muted">{{ shownGroupIdentifier }} </small> <span class="badge badge-info" v-b-popover.hover.top="'Fremtidig menighedsråds-struktur vises'" title="Strukturændring" v-show="showStructureChange">Strukturændring</span></span>
      <slot></slot>
    </div>
  
</template>

<script>
    import {mapGetters} from 'vuex';
    
    export default {
        
        name: "ElectionBoardTitle",
        props: {
            iconClass: String,
            title: String,
            groupName: String,
            groupIdentifier : String,

        },
        data() {
            return {
            }
        },        
        computed: {
            ...mapGetters(["electionDocument",
            "activeUserRole"]),
            shownGroupName(){
                if(this.groupName)
                    return this.groupName;
                
                if(this.electionDocument && this.activeUserRole)
                    return this.electionDocument.aliases.length > 0 ? this.electionDocument.midName : this.activeUserRole.groupName;
                else 
                    return "";
                     
            },
            shownGroupIdentifier(){
                if(this.groupIdentifier)
                    return this.groupIdentifier;
                
                if(this.electionDocument)
                    return this.electionDocument.mid;
                
                return this.activeUserRole ? this.activeUserRole.groupValue : '';
                
            },
            showStructureChange(){
                return this.electionDocument && this.electionDocument.aliases.length > 0;
            }
        }
        
    }
</script>