



















































































































import PortalMixin from "@/mixins/PortalMixin";
import MenuCard from "@/components/MenuCard.vue";

export default {
    name: "PortalStiftHome",
    mixins: [PortalMixin],
    components: {
        MenuCard
    },
    data() {
        return {
            getDataLoading: false,
            showStructureChange: false,
            layout: {
                readMore: false
            }
        }
    }
}

