<template>
    <election-module-card title="Valgforsamling" card-icon-class="fad fa-podium fa-3x"
                          description="Her kan du arbejde med den ordinære valgforsamling og det er her du indstiller om I afholder valget digitalt eller på papir.​"
                          button-icon-class="fal fa-podium"
                          button-text="Gå til valgforsamling"
                          :card-active="cardActive"
                          :route="route">
        <small slot="footer">
            <em class="text-muted">&nbsp;</em>
        </small>
        
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "./ElectionModuleCard.vue";
    import EventCodeType from "@/services/EventCodeType";
    import lodash from 'lodash';
    import moment from "moment";
    
    export default {
        name: "ElectionBoardAssemblyCard",
        extends: ElectionModuleCard,
        props: {
            cardActive: Boolean
        },
        data(){
            return {
                moment: moment,
                lodash: lodash,
                eventTypes : EventCodeType      
            }
        },
        components:{
            ElectionModuleCard
        },
        computed:{            
        },
        created() {
        }
    }
</script>

<style scoped>

</style>