import store from '@/store'

function logIn(msal) {
    console.info("login");
    return msal.signIn().then(() => {
            console.info("resolve");
            setToken(msal);
    })
}

function logOut() {
    localStorage.setItem("token", ``);
}

function setToken(msal) {

    msal.acquireToken().then(d => {
        console.info("aquireToken",d);
    });
    
    console.info("setToken");
    
    let token = msal.data.accessToken;
    if (token !== '')
        // store.state.token = `Bearer ${token}`;
        localStorage.setItem("token", `Bearer ${token}`);
    else
        window.setTimeout(function () {
            setToken(msal);
        }, 100);
}

export default {
    logIn,
    logOut,
    setToken
}