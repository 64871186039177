















import ElectionBoardConstitutionCard from '@/components/Election/Card/ElectionBoardConstitutionCard.vue';
import PortalModuleCard from '@/components/Portal/Card/PortalModuleCard.vue';
import PortalModuleElectionYearCard from '@/components/Portal/Card/PortalModuleElectionYearCard.vue';
import PortalModuleEmptyCard from '@/components/Portal/Card/PortalModuleEmptyCard.vue';
import PortalModuleExternalCard from '@/components/Portal/Card/PortalModuleExternalCard.vue';
import PortalModuleExternalNonBlankCard from '@/components/Portal/Card/PortalModuleExternalNonBlankCard.vue';
import PortalModuleLinkCard from "@/components/Portal/Card/PortalModuleLinkCard.vue";
import PortalModuleSubCard from '@/components/Portal/Card/PortalModuleSubCard.vue';
import PortalModuleSubExternalCard from '@/components/Portal/Card/PortalModuleSubExternalCard.vue';
import PortalModuleSubExternalNonBlankCard from '@/components/Portal/Card/PortalModuleSubExternalNonBlankCard.vue';
import APIService from "@/services/apiservice";

export default {
  name: 'CardGrid',
  components: {
    ElectionBoardConstitutionCard,
    PortalModuleCard,
    PortalModuleElectionYearCard,
    PortalModuleEmptyCard,
    PortalModuleExternalCard,
    PortalModuleExternalNonBlankCard,
    PortalModuleLinkCard,
    PortalModuleSubCard,
    PortalModuleSubExternalCard, 
    PortalModuleSubExternalNonBlankCard,
  },
  watch: {
    $route(to, from) {
      this.loadCards();
    }
  },
  props: {
    mid: {
      type: String,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      cards: [],
      loading: false
    };
  },
  methods: {
    loadCards() {
      this.loading = true;
      const midAsInt = parseInt(this.mid);
      console.log("Cards loading", midAsInt);
      APIService.GET(`/Cards/Get/${midAsInt}/${this.page}`)
        .then(response => {
          this.cards = response.data;
          console.log("Cards loaded", this.cards);
        })
        .catch(error => {
          console.error('Error fetching cards:', error);
          this.cards = []; // Set to empty array if there's an error
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getComponentType(cardType) {
      switch (cardType) {
        case 'ElectionBoardConstitutionCard':
          return ElectionBoardConstitutionCard;
        case 'PortalModuleCard':
          return PortalModuleCard;
        case 'PortalModuleElectionYearCard':
          return PortalModuleElectionYearCard;
        case 'PortalModuleEmptyCard':
          return PortalModuleEmptyCard;
        case 'PortalModuleExternalCard':
          return PortalModuleExternalCard;
        case 'PortalModuleExternalNonBlankCard':
          return PortalModuleExternalNonBlankCard;
        case 'PortalModuleLinkCard':
          return PortalModuleLinkCard;
        case 'PortalModuleSubCard':
          return PortalModuleSubCard;
        case 'PortalModuleSubExternalCard':
          return PortalModuleSubExternalCard;
        case 'PortalModuleSubExternalNonBlankCard':
          return PortalModuleSubExternalNonBlankCard;
        default:
          console.warn(`Unknown card type: ${cardType}`);
          return PortalModuleEmptyCard;
      }
    }
  },
  created() {
    this.loadCards();
    console.log("This card page is shown ",this.page);
  }
}
