




















































































// Libs
import $ from "jquery";
import PortalMixin from "@/mixins/PortalMixin";
import {mapGetters} from "vuex";


import PortalHomeInfo from '@/components/Portal/HomeInfo.vue';

export default {
        name: "PortalHome",
        mixins: [PortalMixin],
        components: {
            PortalHomeInfo
        },
        props: {},
        data() {
            return {
                getDataLoading: false,
                environment: process.env.NODE_ENV.trim(),
                showStructureChange: false,
                layout: {
                    readMore: false
                }
            }
        },
        updated() {
            $('.selectpicker').selectpicker('refresh');
        },
        computed: {
            ...mapGetters(["hasElectionAdminGlobalRole",
              "getElectionYear",
              "activeUserRole"]),
            hasAccounting() {
                return this.hasRole(AccessRoleTypes.Accounting) && this.activeUserRole.level === OrganizationLevelTypes.Menighedsraad;
            }
        },
        methods: {
            functionBlocked() {
                return this.environment === "production" || this.environment === "test";
            },
            navigateToRoute(route) {
                this.$router.push(route);
            }
        },
        created() {


        }

    }

