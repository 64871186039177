import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Router from 'vue-router'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import 'bootstrap';
import VueAppInsights from 'vue-application-insights'
import Vuelidate from 'vuelidate'

import '../static/js/toastr-config.js'
import 'bootstrap-select/dist/js/bootstrap-select.js';
import 'bootstrap-select/dist/js/i18n/defaults-da_DK.min.js';
import 'moment/locale/da.js';
import 'numeral/locales/da-dk.js';
import 'blueimp-file-upload/js/jquery.iframe-transport.js';
import 'blueimp-file-upload/js/vendor/jquery.ui.widget.js';
import 'blueimp-file-upload/js/jquery.fileupload.js';
import serviceContainer from '@/services/service-container'
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ElectionLayout from '@/layouts/ElectionLayout.vue';
import PortalLayout from '@/layouts/PortalLayout.vue';
import ElectionBoardTitle from "@/components/Election/ElectionBoardTitle.vue";
import ElectionBoardDioceseTitle from "@/components/Election/ElectionBoardDioceseTitle.vue";
import {EventBus} from "@/services/EventBus";
import AccessRoleTypes from '@/services/AccessRoleTypes';
import moment from 'moment'

moment.locale('da');

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Router);
Vue.use(VueScrollTo);
Vue.use(VueAppInsights, {
    id: __APPINSIGHTSID__, enableAutoRouteTracking: true, router
});

Vue.component('election-layout', ElectionLayout);
Vue.component('portal-layout', PortalLayout);
Vue.component('default-layout', DefaultLayout);
Vue.component('ElectionBoardTitle', ElectionBoardTitle);
Vue.component('ElectionBoardDioceseTitle', ElectionBoardDioceseTitle);


Vue.filter("longdate", function (value) {
    if (!value) return ''
    value = moment(value, 'DD-MM-YYYY').format('D. ') + moment(value, 'DD-MM-YYYY').format('MMMM').toLowerCase();
    return value;
});

Vue.filter("year", function (value) {
    if (!value) return ''
    value = moment(value).format('YYYY');
    return value;
});

Vue.filter("fulldate", function (value) {
    if (!value) return ''
    value = moment(value).format('LLLL');
    return value.substr(0, value.indexOf(' kl.'));
});

Vue.filter("date", function (value) {
    if (!value) return ''
    value = moment(value).format('DD-MM-YYYY');
    return value;
});

Vue.filter("time", function (value) {
    if (!value) return ''
    value = moment(value).format('HH:mm');
    return value;
});

router.beforeEach((to, from, next) => {

    if (!to.path.startsWith("/login") && (store.getters.token === null)) {
        next({
            path: '/login', query: {redirect: to.fullPath}
        })
    } else {


        if (to.meta.admin) {
            console.log("Role", store.getters.hasElectionAdminGlobalRole);
            if (store.getters.hasElectionAdminGlobalRole) {
                console.log("true");
                next()
            } else {
                console.log("false");
                next({
                    path: '/valg'
                })
            }
        } else {
            if (to.params.period) {
                store.commit('setElectionYear', to.params.period);
            }

            if (to.params.year) {
                store.commit('setActiveYear', to.params.year);
            }

            next();
        }

        window.scrollTo(0, 0);
    }
});


Vue.config.productionTip = false;


new Vue({
    router, store, provide: serviceContainer, render: (h) => h(App), created() {

    }
}).$mount('#app');


