<template>
    <PortalModuleExternalNonBlankCard title="Konstituering" 
                                      description="Menighedsrådets valgte og fødte medlemmer samt konstitution." 
                                      button-text="Gå til konstituering" 
                                      icon-class="fad fa-user-friends" 
                                      :card-active="true" 
                                      footer="&nbsp;" 
                                      :link="`${konstitueringBaseUrl}/${midFromUrl}`">
    </PortalModuleExternalNonBlankCard>
</template>

<script>
    import PortalModuleExternalNonBlankCard from '@/components/Portal/Card/PortalModuleExternalNonBlankCard.vue';
    import PortalMixin from "@/mixins/PortalMixin";

    export default {
        mixins: [PortalMixin],
        name: "ElectionBoardConstitutionCard",
        components:{
            PortalModuleExternalNonBlankCard
        },
        computed: {
            konstitueringBaseUrl() {
                return __KONSTITUERING_BASEURL__;
            }
        },
        props: {
        }
    }
</script>

<style scoped>

</style>