






















import store from "@/store";
import {msalConfig, apiConfig} from '@/settings.config';

export default {
    name: 'Login',
    components: {},
    data() {
        return {
            account: null,
        }
    },
    computed: {
        token() {
            return store.state.token;
        }
    },
    watch: {},
    methods: {
        generateRandomString(length) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

            return text;
        },
        login() {

            //Get Authorization code

          
            
            
            if(this.$route.query.code){

                const url = "https://login.microsoftonline.com/" + msalConfig.auth.tenantId + "/oauth2/v2.0/token";
                const options = {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body:   "client_id=" + msalConfig.auth.clientId + "\n" +
                            "&scope=" + apiConfig.scopes[0] + "\n" +
                            "&code=" + this.$route.query.code + "\n" +
                            "&redirect_uri=" + msalConfig.auth.redirectUri + "\n" +
                            "&grant_type=authorization_code\n" +
                            "&code_verifier="+ window.sessionStorage.getItem("codeVerifier")
                };
                
                fetch(url, options)
                        .then((response) => response.json()) 
                        .then((tokenObject) => {
                            this.$store.commit("setToken",tokenObject);
                            window.sessionStorage.removeItem("codeVerifier")

                            const redirUrl = window.sessionStorage.getItem("redirect");
                            if (redirUrl){
                                window.sessionStorage.removeItem("redirect");
                                this.$router.push(redirUrl);
                            }
                            else
                                this.$router.push({name : 'PortalModule'});
                });
                
                return;
            }
            else {

                if (this.$route.query.redirect)
                    window.sessionStorage.setItem("redirect",this.$route.query.redirect);
                
                var codeVerifier = this.generateRandomString(43);
                crypto.subtle.digest("SHA-256", new TextEncoder().encode(codeVerifier)).then(digest => {
                    var hashed = btoa(String.fromCharCode(...new Uint8Array(digest)))
                            .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')


                    
                    window.sessionStorage.setItem("codeVerifier",codeVerifier);

                    var url = "https://login.microsoftonline.com/" + msalConfig.auth.tenantId + "/oauth2/v2.0/authorize?\n" +
                            "client_id=" + msalConfig.auth.clientId + "\n" +
                            "&response_type=code\n" +
                            "&redirect_uri=" + msalConfig.auth.redirectUri + "\n" +
                            "&response_mode=query\n" +
                            "&scope=" + apiConfig.scopes[0] + "\n" +
                            "&code_challenge="+hashed+"\n" +
                            "&code_challenge_method=S256"

                    window.location = url;

                });
            }
        }
    },
    created() {
        
        if(!this.$store.getters.token)
        {
            this.login();
            return;
        }
        
        if (this.$route.query.redirect)
            this.$router.push(this.$route.query.redirect);
        else
            this.$router.push({name : 'PortalModule'});
    }
}
